import * as React from 'react';
import clsx from 'clsx';
//styles
import styles from '../technologies.module.css';
//components
import Booking from 'components/feedback';
import Bullets from 'components/bullets';
import GetConsultation from 'components/free-consultation';
import Recognition from 'components/recognition';
import Tabs from 'components/tabs';
import Layout from '../components/layout';
import Blog from 'components/blog/widget';
import { Anuel } from 'components/carousel';
import FAQ from 'components/faq';
//meta
import identificators from 'components/googleAnalyticsIdentificators';
import { meta } from '../../../metaData';
//hooks
import { useFaqData } from 'hooks/useFaqData';

const LIST = [
  {
    title: 'Unprecedented transparency',
    text: 'Thanks to Blockchain technology, you can <span>view and analyze transaction history</span> and all related information, including any changes to the public Blockchain environment. Blockchain data is clear, complete and accurate, resulting in outstanding accountability, enabling you to <span>take full control over your budget and spendings</span>.',
  },
  {
    title: 'Optimized efficiency',
    text: 'Since the Blockchain’s architecture is decentralized, it provides high-speed transactions, while ensuring there are no third parties involved in the process. You can also adopt <span>digital-driven smart contracts to strengthen and automate the agreements between stakeholders</span>. These forward-looking approaches allow for reducing transaction fees and improving the overall performance of your business.',
  },
  {
    title: 'Rock-solid security',
    text: 'Blockchain is a digital ledger that was meant to be invulnerable to data breaches. Every feature here is aimed at maximizing safety during operations so that end-users can enjoy a highly secured platform. <span>The system is empowered with a cryptographic hash function</span>, which is a remarkable unique security algorithm designed to spot a data breach – each block has a hash of the previous neighbor block, so changing a single block will make all the following ones invalid.',
  },
];

const TABS_TITLES = [
  {
    title: 'Enterprise-grade Blockchain',
  },
  {
    title: 'Smart contracts',
  },
  {
    title: 'E-wallets',
  },
  {
    title: 'Blockchain-based marketplace',
  },
  {
    title: 'Blockchain consulting',
  },
];

interface Props {
  pathContext: {
    prePost: ReadonlyArray<any>;
  };
  location: Location;
}

function Blockchain({ pathContext: { prePost }, location }: Props) {
  const faqData = useFaqData('allStrapiTechnology', 'Blockchain');

  return (
    <Layout
      title="Blockchain Development Service"
      subtitle="We deliver highly reliable and tamper-proof Blockchain apps that will help you process greater volumes of data, while ensuring substantial cost reductions and enhanced efficiency of all processes."
      linkText="Estimate your project"
      metaData={{ main: meta.blockchain, faq: faqData?.list }}
      pathname={location.pathname}
      linkId={identificators.ESTIMATE_YOUR_PROJECT_HEADER}
    >
      <section className={clsx('section', 'gray-wave')}>
        <div className="inner">
          <h2 className="title">How can Blockchain empower your ecosystem?</h2>
          <p className="subtitle">
            Reshape your business with the ever-evolving Blockchain technology
          </p>
          <Bullets className={styles.bullets} list={LIST} />
        </div>
      </section>
      <section className="section">
        <div className="inner">
          <h2 className="title">Blockchain-driven services we deliver</h2>
          <p className="subtitle">
            We underpin your business with robust Blockchain solutions while following your business
            values and paradigms.
          </p>
          <Tabs list={TABS_TITLES} isArrow btnClassName="tabs__btn_30">
            <li className="tabs__item">
              <div className="tabs__column_first">
                <p>
                  We leverage the enormous potential of Hyperledger Fabric technology to empower
                  corporations with state-of-the-art solutions that can be implemented by multiple
                  industries and domains. Unlike other Blockchain-driven technologies, this platform
                  has in-built plug-and-play features that support private data transfers and
                  confidential agreements. Designed to be used within private enterprises, this
                  framework provides outstanding flexibility and scalability while ensuring data
                  privacy of your transactions.
                </p>
              </div>
              <div className="tabs__column_last" />
            </li>
            <li className="tabs__item">
              <div className="tabs__column_first">
                <p>
                  We can strengthen your Blockchain ecosystem with a smart contract technology that
                  is well-known for its immutability since it cannot be changed or edited. Smart
                  contracts contain specific conditions of agreements and rules, as well as
                  automatically verify their fulfillment and execute the agreed terms, resulting in
                  a delightful system performance. The technology also excludes any third parties
                  and intermediaries, thus enhancing transaction confidentiality and reducing your
                  expenses.
                </p>
              </div>
              <div className="tabs__column_last" />
            </li>
            <li className="tabs__item">
              <div className="tabs__column_first">
                <p>
                  If you require additional security measures to be deployed within your Blockchain
                  infrastructure, our seasoned experts can develop tamper-proof digital wallets,
                  based on multi-signature principle. This principle ensures that any transaction
                  must be confirmed by all owners first to become legit.
                </p>
              </div>
              <div className="tabs__column_last" />
            </li>
            <li className="tabs__item">
              <div className="tabs__column_first">
                <p>
                  We can embrace Blockchain power to either create an e-commerce marketplace system
                  from scratch or reinvent a traditional online multi-store platform. Once you
                  decide to opt for this far-sighted development approach, you can come up with a
                  transparent, smooth and instant online payment system while avoiding fees for
                  foreign transactions. Besides, you win customers’ loyalty and trust by delivering
                  data transparency and traceability across all sectors of the supply chain.
                </p>
              </div>
              <div className="tabs__column_last" />
            </li>
            <li className="tabs__item">
              <div className="tabs__column_first">
                <p>
                  Driven by our extensive experience in the latest tech trends and Blockchain-driven
                  software development, we provide professional advice on how to integrate
                  cutting-edge Blockchain solutions with your existing working environments. We are
                  eager to share our knowledge and help you reshape your business with disruptive
                  technology, so you can maximize your profits and acquire new customers.
                </p>
              </div>
              <div className="tabs__column_last" />
            </li>
          </Tabs>
        </div>
      </section>

      <section className={styles.carousel}>
        {/* <SwiperSlider> */}
        <Anuel />
        {/* </SwiperSlider> */}
      </section>

      <section className="section">
        <div className="inner">
          <h2 className="title">Recognition</h2>
          <Recognition />
        </div>
      </section>
      <GetConsultation
        text="Gain a competitive edge with our full-cycle Blockchain development"
        title="Hire our Blockchain experts"
        linkId={identificators.ESTIMATE_A_PROJECT_BANNER}
      />
      <section className="section">
        <div className="inner">
          <FAQ data={faqData} />
          <Blog posts={prePost} />
        </div>
      </section>
      <Booking />
    </Layout>
  );
}

export default Blockchain;
